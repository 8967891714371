<template>
    <v-card tile flat>      
        <PageLinkHeader :router-link="{name: routeNames.SETTINGS_INVOICING_PARENT}" link-text="Terug naar facturatieconfiguraties" :header="'Facturatieconfiguratie van ' + organizationName"/>
        <PromiseContainer :promises.sync="promises">
            <template v-slot:default>
                <v-form v-model="valid">
                    <v-card flat>
                        <v-card-title>Nummering</v-card-title>
                        <v-card-text class="text--primary">
                            <p class="ma-0">U kan automatisch het jaartal of het maandnummer in het voorvoegsel
                                gebruiken.
                                Gebruik hiervoor de variabelen <b class="notranslate">[jaar]</b> of <b class="notranslate">[maand]</b>.</p>
                            <br>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field required persistent-placeholder label="Voorvoegsel" type="text"
                                        v-model="invoiceConfig.numbering.prefix"
                                        :rules="getRequiredRules('Voorvoegsel moet ingevuld worden')"
                                        @change="getNextNumbers()"></v-text-field>
                                </v-col>
                               
                                    <v-col cols="12" md="3">
                                        <v-text-field required persistent-placeholder label="Beginnen op" type="number"
                                            v-model="invoiceConfig.numbering.initialNumber"
                                            :prefix="numberPrefix"
                                            min="1"
                                            max="999999"
                                            :rules="[getRequiredRules('Beginnen op moet ingevuld worden'),getBiggerThanZeroRules('nummer moet hoger zijn dan 0')].flat()"
                                            @change="getNextNumbers()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                    <v-text-field readonly persistent-placeholder label="Volgend nummer" type="text"
                                        v-model="nextNumbers.invoice"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field readonly persistent-placeholder label="Volgend ontwerp nummer"
                                        type="text" v-model="nextNumbers.draft"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
                <v-row>
                    <v-col v-if="suborganizationId" cols="auto" class="flex-grow-1" align="start">
                        <v-btn color="secondary" :disabled="!valid" @click="onDeleteClick()">Resetten</v-btn>
                    </v-col>
                    <v-col cols="auto" class="flex-grow-1" align="end">
                        <v-btn color="secondary" :disabled="!valid" @click="onSaveClick()">Opslaan</v-btn>
                    </v-col>
                </v-row>
            </template>
        </PromiseContainer>
    </v-card>
</template>

<script>
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import { getRequiredRules, getBiggerThanZeroRules } from '@/shared/utils/inputRulesUtils.js';
import PageLinkHeader from "@/components/shared/PageLinkHeader";
import routeNames from "@/router/RouteNames";

export default {
    name: "InvoiceConfigEdit",
    components: {
        PromiseContainer,
        PageLinkHeader
    },
    props: {
        suborganizationId: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            routeNames,
            promises: [
                
            ],
            invoiceConfig: {
                numbering: {
                    prefix: null,
                    initialNumber: null,
                    lastAssignedInvoice: null,
                    lastAssignedDraft: null
                }
            },
            savedInvoiceConfig: {
                numbering: {
                    prefix: null,
                    initialNumber: null,
                    lastAssignedInvoice: null,
                    lastAssignedDraft: null
                }
            },
            nextNumbers: {
                invoice: null,
                draft: null
            },
            valid: false,
            organizationName: null
        }
    },
    created() {
        this.fetchInvoiceConfig(),
        this.fetchOrganizationName()
    },
    methods: {
        getRequiredRules,
        getBiggerThanZeroRules,
        numberingChanged() {
            return this.invoiceConfig?.numbering?.prefix !== this.savedInvoiceConfig?.numbering?.prefix
                || this.invoiceConfig?.numbering?.initialNumber !== this.savedInvoiceConfig?.numbering?.initialNumber;
        },
        addLeadingZeros(num, targetLength) {
            let numStr = num.toString();
            return numStr.padStart(targetLength, 0);
        },
        onSaveClick() {
            this.promises.push(
                this.$store.dispatch('invoiceConfigModule/updateInvoiceConfig', {invoiceConfig: this.invoiceConfig, suborganizationId: this.suborganizationId})
                    .then(
                        invoiceConfigDTO => {
                            this.promises.push(this.$store.dispatch("setSuccessMessage", { message: "Facturatieconfiguratie werd succesvol aangepast." }))
                            this.handleInvoiceConfigDTOResult(invoiceConfigDTO)
                        }
                    )
            )
        },
        onDeleteClick() {
            this.promises.push(
                this.$store.dispatch('invoiceConfigModule/deleteSubInvoiceConfig', this.suborganizationId)
                    .then(() => {
                        this.promises.push(this.$store.dispatch("setSuccessMessage", { message: "Facturatieconfiguratie werd succesvol gereset." }))
                        this.fetchInvoiceConfig()
                    })
            )
        },
        getNextNumbers() {
            if (this.invoiceConfig.numbering.prefix && this.invoiceConfig.numbering.initialNumber) {
                // if one of the parameters have been amended, the assigned numbers are not valid anymore
                if (this.numberingChanged()) {
                    this.invoiceConfig.numbering.lastAssignedDraft = "";
                    this.invoiceConfig.numbering.lastAssignedInvoice = "";
                } else {
                    this.invoiceConfig.numbering = { ... this.savedInvoiceConfig?.numbering };
                }
                this.$store.dispatch('invoiceConfigModule/fetchNextNumbers', this.invoiceConfig)
                    .then(
                        nextNumbers => {
                            this.nextNumbers.invoice = nextNumbers.nextInvoice;
                            this.nextNumbers.draft = nextNumbers.nextDraft;
                        }
                    )
            } else {
                // if one of the parameters is not filled in, no calculation can be done
                this.nextNumbers.invoice = "";
                this.nextNumbers.draft = "";
            }
        },
        setInvoiceConfigs(newInvoiceConfig) {
            this.invoiceConfig = {
                ...newInvoiceConfig,
                numbering: { ...newInvoiceConfig.numbering }
            };
            this.savedInvoiceConfig = {
                ...newInvoiceConfig,
                numbering: { ...newInvoiceConfig.numbering }
            };
            this.getNextNumbers();
        },
        handleInvoiceConfigDTOResult(invoiceConfigDTO) {
            if (invoiceConfigDTO) {
                if (this.suborganizationId) {
                    const subInvoiceConfig = invoiceConfigDTO.subInvoiceConfigs?.find(sic => sic.suborganizationId == this.suborganizationId)
                    if (subInvoiceConfig) {
                        this.setInvoiceConfigs(subInvoiceConfig)
                        return;
                    }
                } 
                this.setInvoiceConfigs(invoiceConfigDTO)
            }
        },
        fetchInvoiceConfig() {
            this.promises.push(this.$store
                    .dispatch('invoiceConfigModule/fetchInvoiceConfig', this.suborganizationId ? [this.suborganizationId] : null)
                    .then(invoiceConfigDTO => this.handleInvoiceConfigDTOResult(invoiceConfigDTO))
            )
        },
        fetchOrganizationName() {
            const organizationId = this.$store.getters["authModule/getOrganizationId"]
            if (this.suborganizationId) {
                this.promises.push(
                    this.$store.dispatch('suborganizationModule/fetchSuborganizationName', {organizationId, suborganizationId: this.suborganizationId})
                        .then(data => this.organizationName = data)
                )
            } else {
                this.promises.push(
                    this.$store.dispatch('organizationModule/fetchOrganizationName', organizationId)
                        .then(data => this.organizationName = data?.name)
                )
            }
        }
    },
    computed:{
        numberPrefix(){
            let targetLength = 6 - this.invoiceConfig.numbering.initialNumber?.toString().length;
            if (targetLength>0) {
                return this.addLeadingZeros(0,targetLength);
            }else{
                return "";
            }
        }
    }

}
</script>

<style scoped></style>